<template>
    <div class="emb-termCondition-wrap">
        <!-- <emb-page-title heading="Condicones de uso" subHeading></emb-page-title> -->
        <div class="emb-tnc section-gap">
            <div class="container">
                <h1 class="text-center">CONDICIONES DE USO</h1>
                <div class="tnc-content">
                    <v-row>
                        <v-col cols="12" xl="8" lg="10" md="12" offset-xl="2" offset-lg="1">
                            <div class="emb-card mb-12 pa-6 justify" style="text-align:justify">
                                <p>
                                    Estimado Cliente, a continuación te presentamos los Términos y Condiciones de nuestra Política de Delivery.
                                    Te recomendamos que la revises cuando realices tus pedidos por este canal.
                                </p>

                                <h2>Medidad de seguridad</h2>

                                <ol>
                                    <li>
                                        <p>
                                        Por temas de seguridad sanitaria los repartidores entregaran los pedidos en la puerta principal de la casa,
                                        condominio, edificio, empresas, etc. Es decir el motorizado no ingresará ni subirá a los departamentos, áreas, torres, etc.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        Con respecto a la preparación, empaque y entrega de los productos, garantizar que como empresa estamos
                                        cumpliendo con todos los protocolos de seguridad.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Protección por COVID-19</h3>
                                        <ol>
                                            <li>
                                                <p>
                                                    Para recibir el pedido, es necesario que te coloques tu mascarilla, nuestro repartidor también lo hará.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    El Motorizado contará con implementos de limpieza y desinfección antes, durante y después a la entrega.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Mantendremos 2 mts. de distancia durante todo el proceso de entrega para cuidarte y cuidarnos.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Contamos con un proceso diferenciado para pago en efectivo. Sin embargo, para mayor comodidad y evitar 100% contacto, te recomendamos hacer el pago en línea.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Todas las entregas se realizarán en la puerta o recepción del edificio o condominio.
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="8" lg="10" md="12" offset-xl="2" offset-lg="1">
                            <div class="emb-card mb-12 pa-6 justify" style="text-align:justify">

                                <h2>Sobre el servicio delivery</h2>

                                <ol>
                                    <li>
                                        <p>
                                        El servicio de delivery se brinda respecto de aquellos pedidos efectuados por los Clientes a través de las siguientes vías: (i) vía telefónica o (ii) vía online, 
                                        realizando el pedido a través de la web (tienda virtual web).
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        La zona de Reparto es limitada e informada por el operador en la llamada. 
                                        Si hace el pedido vía online, se indicará la cobertura de reparto al ingresar la dirección en la plataforma web.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        No se aceptan billetes mayores de $50.00 como forma de pago.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        La empresa no se hace responsable por el estado de los pedidos que hubieran sido adquiridos por intermedio de terceras empresas.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        Si el pedido incluyera bebidas alcohólicas, esta solo se expenderá a personas mayores de 18 años que muestren su DNI al momento de la entrega del pedido. 
                                        El repartidor está facultado para negarse a entregar el pedido en caso no se cumpla con mostrar el DNI.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        Es responsabilidad del cliente asegurarse que al momento de la entrega del producto, este se encuentra conforme.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        La empresa se reserva el derecho de suspender el servicio de delivery en cualquier momento.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="8" lg="10" md="12" offset-xl="2" offset-lg="1">
                            <div class="emb-card mb-12 pa-6 justify" style="text-align:justify">
                                
                                <h2>Harario de delivery</h2>
                                
                                <p>
                                    Los horarios de atención del delivery podrán estar sujetos a modificaciones por diferentes motivos, entre ellos: cierre de tienda, días festivos y/o feriados, huelgas, condiciones de clima (lluvias, neblina, garúa, etc.), desastres naturales (sismos, terremotos, huaycos, entre otros), días de eventos deportivos/partidos de fútbol o ubicación de la vivienda (dificultades de acceso en determinadas horas, incremento de peligrosidad, etc.); lo cual será informado al momento en que usted se contacte con nosotros para realizar su pedido.
                                </p>
                                <p>
                                    El delivery de las tiendas ubicadas en la Provincia de Lima y Callao por lo general atienden en los siguientes horarios. Sin embargo, podría ocurrir que las tiendas modifiquen sus horarios de acuerdo a lo señalado en el párrafo anterior o se encuentren dentro del listado de tiendas exceptuadas a dicho horario.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import api from "Api";
    export default {
        data() {
            return {
            tnc: null
            };
        },
        mounted() {
            // this.getFaq();
        },
        methods: {
            getFaq() {
            api
                .get("term_condition.json")
                .then(response => {
                this.tnc = response.data;
                // console.log(this.tnc);
                })
                .catch(error => {
                console.log(error);
                });
            }
        }
    };
</script>

